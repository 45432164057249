import React from 'react'
import { graphql } from 'gatsby'

import DatoCMSModel from '../models/DatoCMSModel'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import PageHeader from '@c/page-header'

import TeamPhotos from '@v/team/photos'
import TeamJoin from '@v/team/join'

const Page = ({ data }) => {
  const { page, teamPage } = data
  const { title, headerTitle } = page
  const model = new DatoCMSModel(page)

  return (
    <Layout locale={page.locale} seo={{ ...model.getSeoComponentProps() }}>
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb parentPages={[]} currentPage={title} />
          </div>
        </div>
      </div>

      {/* header*/}
      <div className="container">
        <div className="col-12 col-lg-6 offset-lg-3 ta-center">
          <PageHeader title={headerTitle} />
        </div>
      </div>

      <TeamPhotos
        locale={page.locale}
        teamPageSlug={teamPage.slug}
        {...(page.team[0] || {})}
      />

      <TeamJoin {...(page.teamJoin[0] || {})} />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query TeamPageQuery($locale: String) {
    page: datoCmsTeamPage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      title
      headerTitle

      team {
        members {
          nickname
          firstName
          lastName
          jobPosition
          profileImage {
            alt
            asset {
              localImage {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }

      teamJoin {
        title
        description
        buttonText
        buttonUrl
      }
    }

    teamPage: datoCmsTeamPage(locale: { eq: $locale }) {
      slug
    }
  }
`
