import React from 'react'
import { Link } from 'gatsby'
import makeSlug from '@u/make-slug'
import ProjectImage from '@/components/project-image'

const TeamPhotos = ({ locale, members, teamPageSlug }) => {
  // sort the members by alphabetically
  members.sort((a, b) =>
    `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
  )

  return (
    <div className="container">
      <div className="row mt-40 mt-lg-60">
        {members.map((user, index) => (
          <div key={index} className="col-12 col-lg-3 mb-32">
            <Link
              className="member-link px-0"
              style={{ position: 'relative', display: 'block' }}
              to={makeSlug.generic(locale, teamPageSlug, user.nickname)}
            >
              <ProjectImage
                className="col-12 object-fit px-32 px-lg-0"
                image={user.profileImage}
              />
              <div
                className="overlay d-flex flex-column align-items-center justify-content-end mx-0"
                style={{ zIndex: 2 }}
              >
                <div
                  style={{
                    position: 'static',
                    marginBottom: 'unset',
                    bottom: 'unset'
                  }}
                  className="btn btn--dark ta-center fw-head-semibold"
                >
                  {user.firstName} {user.lastName}
                </div>
                <p
                  style={{
                    position: 'static',
                    marginBottom: 'unset',
                    bottom: 'unset'
                  }}
                  className="c-white fs-normal ta-center my-4"
                >
                  {user.jobPosition}
                </p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TeamPhotos
