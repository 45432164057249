import React from 'react'

const TeamJoin = ({ title, description, buttonText, buttonUrl }) => {
  return (
    <div className="container">
      <div className="row ta-center mt-80 mt-lg-120">
        <div className="col-12 col-lg-8 offset-lg-2">
          <h4>{title}</h4>
          <div
            className="mt-24"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          <p></p>
          <div className="mt-40">
            <a href={buttonUrl} className="btn btn--red btn--round">
              {buttonText}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamJoin
